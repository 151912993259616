import React, { useCallback, useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import Header from './Header';
import Footer from './Footer';






export const SportSetting = () => {

  const [isLoading, setIsLoading] = useState(false);
  const [sportsNames, setSportsNames] = useState([]);

  const [isActive, setIsActive] = useState(true);


  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  // const [pageNumber, setPageNumber] = useState(2);
  const [totalSportsName, setTotalSportsName] = useState();



  // odds
  const [minOdds, setMinOdds] = useState(0);
  const [maxOdds, setMaxOdds] = useState(0);


  // bookmaker
  const [minBookmaker, setMinBookmaker] = useState(0);
  const [maxBookmaker, setMaxBookmaker] = useState(0);


  // session
  const [minSession, setMinSession] = useState(0);
  const [maxSession, setMaxSession] = useState(0);

  const [sportId, setSportId] = useState(0);
  const [selectSports, setSelectSports] = useState('')




  const fetchSportsName = async () => {
    setIsLoading(true);
    try {

      let response = await fetch(`https://api.s2bet.in/api/getSports`);
      let fetchdata = await response.json();
      console.log(fetchdata);

      console.log(fetchdata.length);

      setTotalSportsName(fetchdata.length);
      setSportsNames(fetchdata);
    } catch (error) {
      console.error("Error fetching getSports api " + error);
    } finally {
      // Set loading state back to false after the request is completed
      setIsLoading(false);
    }

  }

  // function to set data of min and max of odds,bookmaker,session
  const fetchDataForMinAndMaxAmount = async (sportId, sportName) => {
    let res = await fetch(`https://api.s2bet.in/api/checkAndSendData/sportSettingPage/${sportId}`);
    let resData = await res.json();


    console.log('sports data setting : ', resData);
    setSportId(sportId);
    setSelectSports(sportName)

    // setting data from api
    setMinOdds(resData[0].MinOddLimit);
    setMaxOdds(resData[0].MaxOddLimit);
    setMinBookmaker(resData[0].MinBookmakerLimit);
    setMaxBookmaker(resData[0].MaxBookmakerLimit);
    setMinSession(resData[0].MinFancyLimit);
    setMaxSession(resData[0].MaxFancyLimit);
  }


  const updateSportStatus = useCallback(async (event, id) => {
    //  event.preventDefault();

    // let uniId = id;

    Swal.fire({
      title: "Conformation",
      text: "Do you want to change the status",
      icon: "warning",
      confirmButtonText: "Yes",
      showCancelButton: true,
    }).then(async (result) => {
      if (!result.value) return;



      let status;
      event.target.checked ? status = 0 : status = 1;

      // console.log(uniId);
      // setIsActive(Math.floor(Math.random()*25));
      setIsActive((prev => !prev));
      // alert(`${event.target.checked ? "want to active":"want to inactive"}`);
      window.location.reload();
      try {

        let res = await fetch(`https://api.s2bet.in/api/updateSportStatus/${id}/${status}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            // Additional headers if needed
          }
        });


      } catch (error) {
        console.log("error in connecting the api");
      }

    }) // here we go

  }, []);


  // function to update the min max data of odds , bookmaker, session
  const updateDataForMinAndMaxAmount = async () => {
    // isLoading(true)

    try {

      let res = await fetch(`https://api.s2bet.in/api/checkAndUpdateData/sportSettingPage/${sportId}/${minOdds}/${maxOdds}/${minBookmaker}/${maxBookmaker}/${minSession}/${maxSession}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        }
      });
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Your work has been saved",
        showConfirmButton: false,
        timer: 1500
      })
      //   .then(()=>{

      //       window.location.reload();
      //   })

    }
    catch (error) {
      console.log("error in connecting the api");
    } finally {
      // Set loading state back to false after the request is completed
      // setIsLoading(false);
    }


  }

  // handling onChange event in odds,bookmaker,session
  const handleOnChange = (e) => {
    let inputValue = e.target.value;
    let dataName = e.target.name;

    if (dataName == "minOdds") {
      setMinOdds(inputValue);
    }

    if (dataName == "maxOdds") {
      setMaxOdds(inputValue);
    }

    if (dataName == "minBookmaker") {
      setMinBookmaker(inputValue);
    }

    if (dataName == "maxBookmaker") {
      setMaxBookmaker(inputValue);
    }

    if (dataName == "minSession") {
      setMinSession(inputValue);
    }

    if (dataName == "maxSession") {
      setMaxSession(inputValue);
    }

  }

  // function to preventing user to use -ve number
  const disableKey = (e) => {
    let key1 = "-";
    let key2 = "+";

    if (e.key.toLowerCase() == key1 || e.key.toLowerCase() == key2) {
      e.preventDefault();
      Swal.fire({
        position: "top-end",
        icon: "warning",
        title: "Value can't be negative !! use positive number",
        showConfirmButton: false,
        timer: 1500
      });
    }
  }

  console.log(isActive);

  useEffect(() => {

    fetchSportsName();

  }, [isActive, currentPage])



  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  }

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  const handleNextPage = () => {
    if (currentPage < Math.ceil(totalSportsName / 10)) {
      setCurrentPage(currentPage + 1);
    }
  }



  return (
    <>

      {isLoading && <div className="spinner" id="loader-1" style={{ display: 'block' }}></div>}


      {/* <div className="nav-md"> */}
      <div className="container body">

        <Header />
        {/* page content */}
        <div className="right_col" role="main" style={{ minHeight: 278 }}>
          <div className="loader" style={{ display: "none" }} />
          <div className="col-md-12">
            <div className="title_new_at">
              Sport Setting
              <div className="pull-right">
                <button className="btn_common">Back</button>{" "}
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="filter_page  data-background">
              {/*  <form method="post" id="formSubmit" style="color:#000;"><input type="hidden" name="compute" value=""> */}
              {/* </form> */}
            </div>
          </div>
          <div className="">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div id="divLoading" />
              <div className="" id="restable">
                <table
                  className="table table-striped jambo_table bulk_action"
                  id="usr"
                >
                  <thead>
                    <tr className="headings">
                      <th className="darkpurplecolor">S NO.</th>
                      <th className="lightgreencolor">SPORT NAME</th>
                      <th className="lightgreencolor">SPORT SETTING</th>
                      <th className="darkpurplecolor">ACTION</th>
                    </tr>
                  </thead>
                  <tbody id="usetable">
                    {sportsNames.map((sport, index) => {
                      return <tr key={sport.SportId}>
                        <td>{((currentPage - 1) * 10) + index + 1}</td>
                        <td>{sport.SportName}</td>
                        <td>
                          <button
                            style={{ fontSize: 14 }}
                            type="button"
                            onClick={() => { fetchDataForMinAndMaxAmount(sport.SportId, sport.SportName) }}
                            className="btn btn-success btn-xs"
                            data-toggle="modal"
                            data-target="#settingmatch"
                          >
                            <i
                              className="fa fa-gear fa-spin"
                              style={{ fontSize: "14px !important" }}
                              aria-hidden="true"
                            />{" "}
                            Setting
                          </button>
                        </td>
                        <td>
                          <label className="switch">
                            <input type="checkbox" checked={sport.Status} onClick={(event) => updateSportStatus(event, sport.SportId)} />
                            <span className="slider" />
                          </label>
                        </td>
                      </tr>


                    })}


                  </tbody>
                </table>
              </div>
              {/* Pagination */}
              <div className="dataTables_wrapper no-footer">
                <div className="dataTables_info" id="datatable_info" role="status" aria-live="polite">
                  Showing {(currentPage - 1) * 10 + 1} to {Math.min(currentPage * 10, totalSportsName)} of Entries {totalSportsName}
                </div>
                <div className="dataTables_paginate paging_simple_numbers" id="datatable_paginate">
                  <a
                    className={`paginate_button previous ${currentPage === 1 ? 'disabled' : ''}`}
                    onClick={handlePreviousPage}
                    aria-controls="datatable"
                    data-dt-idx={0}
                    tabIndex={0}
                    id="datatable_previous"
                  >
                    Previous
                  </a>
                  {Array.from({ length: Math.ceil(totalSportsName / 10) }, (_, i) => (
                    <a
                      key={i + 1}
                      className={`paginate_button ${currentPage === i + 1 ? 'current' : ''}`}
                      onClick={() => handlePageChange(i + 1)}
                      aria-controls="datatable"
                      data-dt-idx={i + 1}
                      tabIndex={0}
                    >
                      {i + 1}
                    </a>
                  ))}
                  <a
                    className={`paginate_button next ${currentPage === Math.ceil(totalSportsName / 10) ? 'disabled' : ''}`}
                    onClick={handleNextPage}
                    aria-controls="datatable"
                    data-dt-idx={3}
                    tabIndex={0}
                    id="datatable_next"
                  >
                    Next
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="row " style={{ display: "none" }}>
            <div className="col-md-12 col-xs-12 ">
              <div className="title_new_at"> Users List</div>
              <div className=" searchuser" style={{ marginBottom: 10 }}>
                <div
                  className="dataTables_length"
                  id="datatable_length"
                  style={{ padding: 10 }}
                >
                  <label>
                    Show
                    <select
                      name="datatable_length"
                      aria-controls="datatable"
                      className=""
                    >
                      <option value={10}>10</option>
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                    </select>{" "}
                    entries
                  </label>
                </div>
                <div
                  className="col-md-6 col-xs-6"
                  style={{ textAlign: "right", padding: 10, float: "right" }}
                >
                  <form className="usersech block_5" id="userListForm">
                    <input type="hidden" name="formSubmit" defaultValue={1} />
                    <input
                      type="hidden"
                      name="perpage"
                      id="perpage"
                      defaultValue={10}
                    />
                    <div id="datatable_filter" className="dataTables_filter">
                      <label>
                        <input
                          style={{ float: "right", width: "100% !important" }}
                          id=""
                          type="search"
                          className="sr"
                          placeholder="Search Here..."
                          aria-controls="datatable"
                        />
                      </label>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-md-12"></div>
            <div className="col-md-12 col-sm-12 col-xs-12" style={{ padding: 10 }}>
              <div id="divLoading"> </div>
              {/*Loading class */}
            </div>
          </div>
        </div>
        <footer>
          <div className="pull-right" />
          <div className="clearfix" />
        </footer>
      </div>


      <div
        id="settingmatch"
        role="dialog"
        className="modal fade"
        style={{ display: "none" }}
      >
        <input type="hidden" id="settle_userid" />
        <input type="hidden" id="settle_type" />
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="popup_form">
              <div className="title_popup">
                <span id="title_settle" className="ng-star-inserted">
                  {selectSports}
                </span>
                {/**/}
                <button
                  type="button"
                  data-dismiss="modal"
                  className="close"
                  style={{ color: "#fff !important" }}
                >
                  <div className="close_new">
                    <i className="fa fa-times-circle" aria-hidden="true" />
                  </div>
                </button>
              </div>
              <div className="content_popup">
                <div className="popup_form_row">
                  <form
                    style={{ padding: 5 }}
                    noValidate=""
                    className="ng-untouched ng-pristine ng-valid"
                  >
                    <table
                      className="table table-striped jambo_table bulk_action"
                      id="usr"
                    >
                      <thead>
                        <tr className="headings">
                          <th
                            className=""
                            style={{ fontSize: 14, fontWeight: 700 }}
                          >
                            <b />
                            <center>
                              <b> </b>
                              <center>
                                <b />
                              </center>
                            </center>
                          </th>
                          <th
                            className=""
                            style={{ fontSize: 14, fontWeight: 700 }}
                          >
                            <b />
                            <center>
                              <b>Min</b>
                              <center>
                                <b />
                              </center>
                            </center>
                          </th>
                          <th
                            className=""
                            style={{ fontSize: 14, fontWeight: 700 }}
                          >
                            <b />
                            <center>
                              <b>Max</b>
                              <center>
                                <b />
                              </center>
                            </center>
                          </th>
                        </tr>
                      </thead>
                      <tbody id="usetable">
                        <tr>
                          <td>Odds</td>
                          <td>
                            <input
                              type="number"
                              min={0}
                              name="minOdds"
                              value={minOdds}
                              onChange={handleOnChange}
                              onKeyDown={disableKey}
                              id="matchname"
                              className="form-control"
                              placeholder="Min"
                              autoComplete="off"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              min={0}
                              name="maxOdds"
                              value={maxOdds}
                              onChange={handleOnChange}
                              onKeyDown={disableKey}
                              id="matchname"
                              className="form-control"
                              placeholder="Max"
                              autoComplete="off"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Bookmaker</td>
                          <td>
                            <input
                              type="number"
                              min={0}
                              name="minBookmaker"
                              value={minBookmaker}
                              onChange={handleOnChange}
                              onKeyDown={disableKey}
                              id="matchname"
                              className="form-control"
                              placeholder="Min"
                              autoComplete="off"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              min={0}
                              name="maxBookmaker"
                              value={maxBookmaker}
                              onChange={handleOnChange}
                              onKeyDown={disableKey}
                              id="matchname"
                              className="form-control"
                              placeholder="Max"
                              autoComplete="off"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Session</td>
                          <td>
                            <input
                              type="number"
                              min={0}
                              name="minSession"
                              value={minSession}
                              onChange={handleOnChange}
                              onKeyDown={disableKey}
                              id="matchname"
                              className="form-control"
                              placeholder="Min"
                              autoComplete="off"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              min={0}
                              name="maxSession"
                              value={maxSession}
                              onChange={handleOnChange}
                              onKeyDown={disableKey}
                              id="matchname"
                              className="form-control"
                              placeholder="Max"
                              autoComplete="off"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={3}>
                            <button
                              style={{ fontSize: 14 }}
                              type="button"
                              className="btn btn-success btn-xs"
                              aria-hidden="true"
                              data-dismiss="modal"
                            //  onClick={()=>{window.location.reload()}}
                            >
                              Cancle
                            </button>
                            <button
                              style={{ fontSize: 14 }}
                              type="button"
                              data-dismiss="modal"
                              className="btn btn-success btn-xs"
                              onClick={updateDataForMinAndMaxAmount}
                            >
                              Update
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      {/* </div> */}
    </>

  )
}
