import { useEffect, useState } from 'react';
import './App.css';
import Dashboard from './Components/Dashboard';
import Login from './Components/Login';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import AdminList from './Components/AdminList';
import UserList from './Components/userList';
import LoginReport from './Components/LoginReport';
import { SportSetting } from './Components/SportSetting';
import { MatchSetting } from './Components/MatchSetting';
import { GlobalSetting } from './Components/GlobalSetting';
import Announcement from './Components/Announcement';
import AnnouncementUpdating from './Components/AnnouncementUpdating';
import CloseUser from './Components/CloseUser';
import AccountInfo from './Components/AccountInfo';
import AccountStatement from './Components/AccountStatement';
import MatchDetailList from './Components/MatchDetailList';
import BetHistory from './Components/BetHistory';
import ShowBetCr from './Components/ShowBetCr';
import ChipSummary from './Components/ChipSummary';
import Ledger from './Components/Ledger';
import DownlineProfitLoss from './Components/DownlineProfitLoss';
import NetExposure from './Components/NetExposure';
import CuttingExpo from './Components/CuttingExpo';
import DeleteBetHistory from './Components/DeleteBetHistory';
import PrivateRoutes from './utils/PrivateRoutes';
import ErrorPage from './utils/ErrorPage';
import SeriesSetting from './Components/SeriesSetting';
import Expobets from './Components/Expobets';
import AccountStatementUser from './Components/AccountStatmentUser';
import MatchDetailListUser from './Components/MatchDetailListUser';
import ShowBetCrByUser from './Components/ShowBetCrByUser';

function App() {

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const headerNoticeData = async () => {
      try {
        const api = await fetch(`https://api.s2bet.in/api/getMasterData`);
        const apiData = await api.json();
        const faviconUrl = apiData[0].FavIcon;
        //console.log("Favicon URL is : ", faviconUrl);

        // Update favicon
        const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'icon';
        link.href = faviconUrl;
        document.getElementsByTagName('head')[0].appendChild(link);
      } catch (error) {
        //console.error('Error fetching favicon URL:', error);
      }
    };

    headerNoticeData();
  }, []);

  useEffect(() => {
    // Check if user information is present in session storage
    const userName = Cookies.get('userName');
    const userId = Cookies.get('id');

    if (userName && userId) {
      setIsLoggedIn(true);
      Cookies.set('logged-in', true);
    } else {
      setIsLoggedIn(false);
      Cookies.set('logged-in', false);
    }
  }, []);

  return (

    <BrowserRouter>
      <Routes>
        <Route
          exact
          path='/'
          element={isLoggedIn ? <Dashboard /> : <Login />}
        />

        <Route path='/login' element={!isLoggedIn ? <Login /> : <Navigate to='/' />} />
        <Route element={<PrivateRoutes />}>
          <Route path='/' element={<Dashboard />} />
          <Route path='/adminList/:roleId' element={<AdminList />} />
          <Route path='/adminList/:roleId/:Id' element={<AdminList />} />
          <Route path='/userList' element={<UserList />} />
          <Route path='/loginReport/:Id' element={<LoginReport />} />
          <Route path='/sportSetting' element={<SportSetting />} />
          <Route path='/matchSetting' element={<MatchSetting />} />
          <Route path='/globalSetting' element={<GlobalSetting />} />
          <Route path='/announcement' element={<Announcement />} />
          <Route path='/announcementUpdate' element={<AnnouncementUpdating />} />
          <Route path='/closeUser/:roleId/:Id' element={<CloseUser />} />
          <Route path='/accountInfo' element={<AccountInfo />} />
          <Route path='/statementByUser' element={<AccountStatement />} />
          <Route path='/statementByUserId' element={<AccountStatementUser />} />
          <Route path='/matchDetailList/:eventId' element={<MatchDetailList />} />
          <Route path='/matchDetailListByUser/:eventId/:userId/:roleId' element={<MatchDetailListUser />} />
          <Route path='/betHistory' element={<BetHistory />} />
          <Route path='/ShowBetCr/:eid/:marketNumber/:marketSId' element={<ShowBetCr />} />
          <Route path='/ShowBetCrByUser/:eid/:marketNumber/:marketSId/:cuserId/:croleId' element={<ShowBetCrByUser />} />
          <Route path='/chipSummary' element={<ChipSummary />} />
          <Route path='/ledger/:userId/:roleId' element={<Ledger />} />
          <Route path='/downlineProfitLoss' element={<DownlineProfitLoss />} />
          <Route path='/netExposure' element={<NetExposure />} />
          <Route path='/cuttingExpo/:eid/:gameId/:marketId' element={<CuttingExpo />} />
          <Route path='/deleteBetHistory' element={<DeleteBetHistory />} />
          <Route path='/seriesSetting' element={<SeriesSetting />} />
          <Route path='/expoBets/:userId' element={<Expobets />} />



        </Route>
        <Route path='*' element={<ErrorPage />} />
      </Routes>

    </ BrowserRouter>
  )
}

export default App;
