import React, { useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";
import Header from "./Header";
import Footer from "./Footer";

export default function SeriesSetting() {
    const [allData, setAllData] = useState([]);
    const [seriesData, setSeriesData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [isActive, setIsActive] = useState(true);
    const [selectSport, setSelectSport] = useState(0)
    const [reloadAgain, setReloadAgain] = useState(false)
    const entriesPerPage = 25;

    useEffect(() => {
        // Fetch data from API
        fetch('https://api.s2bet.in/getSeriesAllData')
            .then(response => response.json())
            .then(data => {
                setAllData(data.data);
                setSeriesData(data.data)
            })
            .catch(error => console.error('Error fetching data:', error));

            setReloadAgain(false)
    }, [reloadAgain]);

    // const updateSeriesStatus = useCallback(async (event, sportid, seriesId) => {
    //   //  event.preventDefault();

    //   // let uniId = id;

    //   Swal.fire({
    //     title: "Conformation",
    //     text: "Do you want to change the status",
    //     icon: "warning",
    //     confirmButtonText: "Yes",
    //     showCancelButton: true,
    //   }).then(async (result) => {
    //     if (!result.value) return;



    //     let status;
    //     event.target.checked ? status = 0 : status = 1;

    //     // console.log(uniId);
    //     // setIsActive(Math.floor(Math.random()*25));
    //     setIsActive((prev => !prev));
    //     // alert(`${event.target.checked ? "want to active":"want to inactive"}`);
    //     window.location.reload();
    //     try {

    //       let res = await fetch(`https://api.s2bet.in/api/updateSeriesStatus/${sportid}/${seriesId}/${status}`, {
    //         method: 'PUT',
    //         headers: {
    //           'Content-Type': 'application/json',
    //           // Additional headers if needed
    //         }
    //       });


    //     } catch (error) {
    //       console.log("error in connecting the api");
    //     }

    //   }) // here we go

    // }, []);
    const handleAciton = async (event, sportId, seriesId) => {
        let status;
        event.target.checked ? status = 1 : status = 0;

        console.log("sportId : " + sportId + " seriesId : " + seriesId + "Status :" + status)

        Swal.fire({
            title: "Conformation",
            text: "Are you sure",
            icon: "warning",
            confirmButtonText: "Yes",
            showCancelButton: true,
        }).then(async (result) => {
            if (result.isConfirmed) {

                try {
                    // Send login request with username, password, and userIP
                    const response = await fetch('https://api.s2bet.in/api/updateSeriesStatus', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ status, sportId: sportId, seriesId: seriesId }),
                    });

                    const responseData = await response.json();
                    console.log("response from api : " + JSON.stringify(responseData))
                    if (responseData.isSuccess == true) {

                        Swal.fire({
                            position: "top-end",
                            icon: "success",
                            title: "Update Successful..",
                            showConfirmButton: false,
                            timer: 1500
                        });
                        console.log(JSON.stringify(responseData.message))
                        setReloadAgain(true);
                        // filterBetUsers();
                        // handleFilter()
                        // setFilterClicked(true)
                        //setLoad(true)
                        // window.location.reload();
                    } else {

                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Something went wrong!'
                        });
                        console.log(JSON.stringify(responseData.message))
                    }
                } catch (err) {
                    console.error("error is handleAction POST api : " + err)
                }

            }
        })
    }

    const filterBySport = (e) => {
        if (e.target.value != 0) {
            const filterData = allData.filter(item => item.sportId == e.target.value)
            setSeriesData(filterData)
        } else {
            setSeriesData(allData)
        }

    }



    // Function to determine sports name based on sportId
    const getSportsName = (sportId) => {

        switch (sportId) {
            case '1':
                return 'Soccer';
            case '2':
                return 'Tennis';
            case '4':
                return 'Cricket';
            default:
                return 'Unknown';
        }
    };

    // Calculate index of the first and last entry on the current page
    const indexOfLastEntry = currentPage * entriesPerPage;
    const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
    const currentEntries = seriesData.slice(indexOfFirstEntry, indexOfLastEntry);

    // Change page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <>
            <div className="spinner" id="loader-1" style={{ display: "none" }}></div>
            <div className="container body">
                <Header />
                <div className="right_col" role="main" style={{ minHeight: 273 }}>
                    <div className="loader" style={{ display: "none" }} />
                    <div className="col-md-12">
                        <div className="title_new_at">
                            Series Setting
                            <div className="pull-right">
                                <button className="btn_common">Back</button>{" "}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="filter_page  data-background">
                            <div className="block_2">
                                <select
                                    id="sports"
                                    className="form-control"
                                    style={{ color: "black" }}
                                    onChange={filterBySport}
                                >
                                    <option value={0}>Select Sport</option>
                                    <option value={4}>Cricket</option>
                                    <option value={2}>Tennis</option>
                                    <option value={1}>Football</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="table-responsive">
                            <table className="table table-striped jambo_table bulk_action">
                                <thead>
                                    <tr className="headings">
                                        <th className="darkpurplecolor">S.No.</th>
                                        <th className="darkpurplecolor">Series ID</th>
                                        <th className="darkpurplecolor">Series Name</th>
                                        <th className="darkpurplecolor">Sports Name</th>
                                        <th className="darkpurplecolor">ON / OFF</th>
                                    </tr>
                                </thead>
                                <tbody id="usetable">
                                    {currentEntries.map((series, index) => (
                                        <tr key={series.Id}>
                                            <td>{indexOfFirstEntry + index + 1}</td>
                                            <td>{series.seriesId}</td>
                                            <td>{series.seriesName}</td>
                                            <td>{getSportsName(series.sportId)}</td>
                                            <td>
                                                <label className="switch">
                                                    {/* <input type="checkbox" checked={series.status} 
                         
                           onClick={(event) => {event.preventDefault(); handleAciton(series.sportId, series.seriesId)}}
                          /> */}
                                                    <input type="checkbox" checked={series && series.status} onClick={(event) => { event.preventDefault(); handleAciton(event, series.sportId, series.seriesId) }} />

                                                    <span className="slider" />
                                                </label>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="dataTables_wrapper no-footer">
                            <div
                                className="dataTables_info"
                                id="datatable_info"
                                role="status"
                                aria-live="polite"
                            >
                                Showing {indexOfFirstEntry + 1} to {Math.min(indexOfLastEntry, seriesData.length)} of {seriesData.length} Entries
                            </div>
                            <div className="dataTables_paginate paging_simple_numbers" id="datatable_paginate">
                                <a
                                    className={`paginate_button previous ${currentPage === 1 ? 'disabled' : ''}`}
                                    onClick={() => {
                                        if (currentPage !== 1) setCurrentPage(currentPage - 1);
                                    }}
                                    aria-controls="datatable"
                                    data-dt-idx={0}
                                    tabIndex={0}
                                    id="datatable_previous"
                                >
                                    Previous
                                </a>
                                {Array.from({ length: Math.ceil(seriesData.length / entriesPerPage) }, (_, i) => (
                                    <a
                                        key={i}
                                        className={`paginate_button ${currentPage === i + 1 ? 'current' : ''}`}
                                        onClick={() => setCurrentPage(i + 1)}
                                        aria-controls="datatable"
                                        data-dt-idx={i + 1}
                                        tabIndex={0}
                                    >
                                        {i + 1}
                                    </a>
                                ))}
                                <a
                                    className={`paginate_button next ${currentPage === Math.ceil(seriesData.length / entriesPerPage) ? 'disabled' : ''}`}
                                    onClick={() => {
                                        if (currentPage !== Math.ceil(seriesData.length / entriesPerPage)) setCurrentPage(currentPage + 1);
                                    }}
                                    aria-controls="datatable"
                                    data-dt-idx={3}
                                    tabIndex={0}
                                    id="datatable_next"
                                >
                                    Next
                                </a>
                            </div>
                        </div>

                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}