import React, { useEffect, useState } from 'react'
import Footer from './Footer'
import Header from './Header'
import Cookies from 'js-cookie'
import Moment from 'moment-timezone';
import { useParams } from 'react-router-dom'
import Swal from 'sweetalert2';

function Expobets() {


    // const userId = Cookies.get('id')
    const { userId } = useParams();
    const roleId = Cookies.get('roleId')
    const LoggedInuserName = Cookies.get('userName').replace(/['"]+/g, '');

    const [betUsers, setBetUsers] = useState([])
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);


    const [isLoading, setIsLoading] = useState(false);


    const fetchBetHistoryApi = async () => {
        setIsLoading(true)
        try {
            const fetched = await fetch(`https://api.s2bet.in/expoBetHistory/${userId}`);
            const response = await fetched.json();
            console.log("Get BetHistory Api  : " + JSON.stringify(response.data));


            setBetUsers(response.data)

        } catch (error) {
            console.error("Error fetching Users api " + error);
        } finally {
            // Set loading state back to false after the request is completed
            setIsLoading(false);
        }
    };

    const handlePerPageChange = (e) => {
        setPerPage(parseInt(e.target.value));
        setCurrentPage(1); // Reset to the first page when changing items per page
    };






    const indexOfLastItem = currentPage * perPage;
    const indexOfFirstItem = indexOfLastItem - perPage;
    const currentItems = betUsers.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);




    useEffect(() => {
        fetchBetHistoryApi();
        // filterBetUsers()
    }, [])



    return (
        <>

            {isLoading && <div className="spinner" id="loader-1" style={{ display: 'block' }}></div>}
            {/* <div className="nav-md"> */}
            <div className="container body">

                <Header />

                {/* page content */}
                <div className="right_col" role="main" style={{ minHeight: 599 }}>
                    <div className="loader" style={{ display: "none" }} />
                    <div className="col-md-12">
                        <div className="title_new_at">
                            Exposure Bet History
                            <select style={{ color: "black", fontSize: "13px" }} id="pages" onChange={handlePerPageChange}
                                value={perPage}>
                                <option value={10} selected="selected">
                                    10
                                </option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-12" />

                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <div id="divLoading" />
                        {/*Loading class */}
                        <div className="custom-scroll table-responsive">
                            <table
                                className="table table-striped jambo_table bulk_action"
                                id="datatables"
                            >
                                <thead>
                                    <tr className="headings">
                                        <th className="darkpurplecolor">S.No.</th>
                                        <th className="lightgreencolor">Client</th>
                                        <th className="darkpurplecolor" style={{ display: roleId <= "1" ? "table-cell" : "none" }}>Tech Admin</th>
                                        <th className="lightgreencolor" style={{ display: roleId <= "2" ? "table-cell" : "none" }}>Super Admin</th>
                                        <th className="darkpurplecolor" style={{ display: roleId <= "3" ? "table-cell" : "none" }}>Sub Admin</th>
                                        <th className="lightgreencolor" style={{ display: roleId <= "4" ? "table-cell" : "none" }}>Super Super</th>
                                        <th className="darkpurplecolor" style={{ display: roleId <= "5" ? "table-cell" : "none" }}>Super</th>
                                        <th className="lightgreencolor" style={{ display: roleId <= "6" ? "table-cell" : "none" }}>Master</th>
                                        <th className="darkpurplecolor">Description</th>
                                        <th className="lightgreencolor">Selection</th>
                                        <th className="darkpurplecolor">Market</th>
                                        <th className="lightgreencolor">Odds</th>
                                        <th className="darkpurplecolor">Stack</th>
                                        <th className="lightgreencolor">Date</th>
                                        <th className="darkpurplecolor">P_L</th>
                                        <th className="lightgreencolor">Profit</th>
                                        <th className="darkpurplecolor">Liability</th>
                                        <th className="lightgreencolor">Type</th>
                                        <th className="darkpurplecolor">Status</th>
                                        <th className="lightgreencolor">IP</th>
                                    </tr>
                                </thead>
                                <tbody id="betlistdiv">
                                    {currentItems.length > 0 && currentItems.map((item, index) => (

                                        <tr key={item.id} className={item.Type == 'lay' ? 'mark-lay' : 'mark-back'}>
                                            <td>{(currentPage - 1) * perPage + index + 1}</td>
                                            <td>{item.UserN}</td>
                                            <td style={{ display: roleId <= "1" ? "table-cell" : "none" }}>{item.TechAdminN}</td>
                                            <td style={{ display: roleId <= "2" ? "table-cell" : "none" }}>{item.SuperAdminN}</td>
                                            <td style={{ display: roleId <= "3" ? "table-cell" : "none" }}>{item.SubAdminN}</td>
                                            <td style={{ display: roleId <= "4" ? "table-cell" : "none" }}>{item.SuperSuperN}</td>
                                            <td style={{ display: roleId <= "5" ? "table-cell" : "none" }}>{item.SuperN}</td>
                                            <td style={{ display: roleId <= "6" ? "table-cell" : "none" }}>{item.masterN}</td>
                                            <td>{item.Market == "Fancy" ? item.EventName + "/" + item.Event : item.EventName}</td>
                                            <td>{item.Selection}</td>
                                            <td>{item.Market}</td>
                                            <td>{item.OddsRequest}</td>
                                            <td className="">{item.AmountStake}</td>
                                            <td>{Moment(new Date(item.MatchedTime)).tz("Asia/Calcutta").format('ddd MMM DD hh:mm:ss z YYYY')}</td>
                                            <td className={item.ResultAmount < 0 ? 'green' : 'red'}>{item.ResultAmount * (-1)}</td>
                                            <td className="green">{item.Profit}</td>
                                            <td className="red">{item.Liability}</td>
                                            <td>{item.Type}</td>
                                            <td>{item.IsSettlement == 1 ? "SETTLED" : "OPEN"}</td>
                                            <td>{item.IpAddress}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <p id="items">Showing {indexOfFirstItem + 1} to {Math.min(indexOfLastItem, betUsers.length)} of Entries {betUsers.length}</p>
                        <div className="pagination-row dataTables_paginate paging_simple_numbers">
                            {Array.from({ length: Math.ceil(betUsers.length / perPage) }, (_, i) => (
                                <button
                                    key={i + 1}
                                    className={`paginate_button ${currentPage === i + 1 ? 'current' : ''}`}
                                    onClick={() => paginate(i + 1)}
                                >
                                    {i + 1}
                                </button>
                            ))}
                        </div>
                    </div>
                </div>


                <footer>
                    <div className="pull-right" />
                    <div className="clearfix" />
                </footer>
            </div>
            <Footer />
            {/* </div> */}
        </>
    )
}

export default Expobets